import React from "react";
import {ButtonContainer, ButtonType, Checkbox, Dropdown, DropdownRequiredType, InlineType, NumberWidget} from "@renta-apps/athenaeum-react-components";
import ArsenalQuestionInput from "../../components/ArsenalQuestionInput/ArsenalQuestionInput";
import QuestionResourceReportItem from "../Models/QuestionResourceReportItem";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import {ResourceItemType} from "@/models/Enums";
import BaseClassifier from "@/models/server/BaseClassifier";
import UserContext from "@/models/server/UserContext";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import ResourceDefaultValueSelector from "@/helpers/ResourceDefaultValueSelector";
import {CalculateFutureOperatingHoursService} from "@/services/CalculateFutureOperatingHoursService";
import UpdateReturnInspectionReportOperatingHoursRequest from "@/models/server/requests/UpdateReturnInspectionReportOperatingHoursRequest";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import DeviceInfo from "@/models/server/DeviceInfo";
import {ch} from "@renta-apps/athenaeum-react-common";
import {UploadFullImageResponse} from "@/models/UploadFullImageResponse";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import NumbersHelper from "@/helpers/NumbersHelper";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "../RentaToolsController";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import newStyles from "../NewUI.module.scss";
import styles from "./QuestionResourcePage.module.scss";
import FileService from "@/services/FileService";

export interface IQuestionResourcePageProps {
}

interface IQuestionResourcePageState extends IReturnInspectionWizardPageState {
    valueTypes: BaseClassifier[];
    isLoading: boolean;
    isValid : boolean;
    edit: boolean;
    editedValue: number | null;
    calculatedMaxValue: number | null;
}

export default class QuestionResourcePage extends ReturnInspectionWizardPage<IQuestionResourcePageProps, IQuestionResourcePageState> {

    state: IQuestionResourcePageState = {
        valueTypes: [],
        isLoading: false,
        isValid: false,
        edit: false,
        editedValue: null,
        calculatedMaxValue: null,
    };

    private readonly _questionInputRef: React.RefObject<ArsenalQuestionInput> = React.createRef();

    private async initializeValueTypeIdAsync(valuesTypes : BaseClassifier[] ): Promise<void> {
        if (!this.reportItem.valueTypeId) {
            if (this.resourceType == ResourceItemType.Fueling) {
                this.reportItem.valueTypeId = ResourceDefaultValueSelector.getResourceDefaultValue(RentaToolsController.device?.fuelTypeId, this.reportItem.defaultFuelTypeId, valuesTypes);
                RentaToolsController.saveContext();
            }

            if (this.resourceType == ResourceItemType.AdBlue) {
                this.reportItem.valueTypeId = ResourceDefaultValueSelector.getResourceDefaultValue(RentaToolsController.device?.adBlueId, this.reportItem.defaultAdBlueTypeId, valuesTypes);
                RentaToolsController.saveContext();
            }

            if (this.resourceType === ResourceItemType.Washing) {
                this.reportItem.valueTypeId = ResourceDefaultValueSelector.getResourceDefaultValue(undefined, this.reportItem.defaultWashingTypeId, valuesTypes);
                RentaToolsController.saveContext();
            }
        }
    }

    private async validate(): Promise<void> {
        if (this.isItemValid(this.reportItem)) {

            await this.setState({ isValid: true });
            return;
        }

        await this.setState({ isValid: false });
    }

    private async updateValueAsync(value: number): Promise<void> {
        let newValue: number = Number(value.toFixed(2));

        if (newValue != this.initialStepItem?.value) {
            this.reportItem.value = Number(value.toFixed(2));
            await this.setState({editedValue: newValue});
        }
    }

    private async saveOperatingHours(): Promise<void> {
        if (this.state.editedValue) {
            const request: UpdateReturnInspectionReportOperatingHoursRequest = {
                id: this.getReportItem().id,
                reportId: this.report.id,
                operatingHours: this.state.editedValue,
                deviceId: RentaToolsController.device!.id,
            }
            const valid: boolean = await ReturnInspectionController.updateReturnInspectionStepOperatingHours(request);
            super._needToProcessOnLeave = false;
            if (valid) {
                await this.setState({edit: !this.state.edit, editedValue: null});
            }
        }
    }

    private async setInvoicedAsync(invoiced: boolean): Promise<void> {
        if (this.reportItem.invoiced != invoiced) {
            this.reportItem.invoiced = invoiced;
            RentaToolsController.saveContext();
        }
    }

    private async setValueTypeAsync(value: BaseClassifier): Promise<void> {
        if (!value) {
            this.reportItem.valueTypeId = "";
            RentaToolsController.saveContext();
            await this.reRenderAsync();
            await this.validate();
            return;
        }

        if (this.reportItem.valueTypeId != value.id) {
            this.reportItem.valueTypeId = value.id;
            RentaToolsController.saveContext();
            await this.reRenderAsync();
        }

        await this.validate();
    }

    private async setValueAsync(value: number): Promise<void> {
        this.reportItem.value = value;

        // If report resource item invoiced has been edited and value is brought back to 0, we can set invoiced to false automatically
        if (value == 0 && !!this.reportItem.invoiced) {
            this.reportItem.invoiced = false;
        }

            // In cases where user has access and the invoiced value hasn't been changed yet and the value changes
        // we can select the checkbox automatically (as requested by finnish business)
        else if (!ch.isNorway && value > 0 && this.reportItem.invoiced == null && this.isInvoiceable) {
            this.reportItem.invoiced = true;
        }

        RentaToolsController.saveContext();

        await this.validate();

        if ((this.minOperatingHours != null) && (NumbersHelper.less(value, this.minOperatingHours))) {
            this.setState({isValid: false});
            await this.alertErrorAsync(`${Localizer.servicePageMinOperatingHoursError} ${"{0:0.0}".format(this.minOperatingHours)}`);
        } else if ((this.maxOperatingHours != null) && (NumbersHelper.greater(value, this.maxOperatingHours))) {
            this.setState({isValid: false});
            await this.alertErrorAsync(`${Localizer.servicePageMaxOperatingHoursError} ${"{0:0.0}".format(this.maxOperatingHours)}`);
        } else {
            await ch.hideAlertAsync();
        }

        await this.reRenderAsync();
    }

    private get reportItem(): QuestionResourceReportItem {
        return this.getReportItem<QuestionResourceReportItem>();
    }

    private get resourceType(): ResourceItemType {
        return this.reportItem.resourceType || ResourceItemType.Operating;
    }

    private get valueTypes(): BaseClassifier[] {
        return this.state.valueTypes;
    }

    private get fuelingAndWashingEnabled(): boolean {
        const userContext: UserContext = this.getContext();
        return ch.isNorway || userContext.settings.fuelingAndWashingEnabled;
    }

    private get canInvoiceFuelingAndWashing(): boolean {
        return ReportDefinitionItem.canInvoiceFuelingAndWashing(this.reportItem, this.getContext());
    }

    private get isResourceInvoiceable(): boolean {
        const value: BaseClassifier | null = this.valueTypes.find(item => item.id == this.reportItem.valueTypeId) || null;
        return ((value != null) && (value.isInvoiceable));
    }

    private get invoiceFuelingAndWashingLabel(): string {
        switch (this.resourceType) {
            case ResourceItemType.Washing:
                return Localizer.fuelingAndWashingInvoiceWashing;
            case ResourceItemType.Fueling:
                return Localizer.fuelingAndWashingInvoiceFueling;
            case ResourceItemType.AdBlue:
                return Localizer.fuelingAndWashingInvoiceAdBlue;
        }
        return "";
    }

    private get valueTypeLabel(): string {
        switch (this.resourceType) {
            case ResourceItemType.Washing: return Localizer.fuelingAndWashingWashingType;
            case ResourceItemType.Fueling: return Localizer.fuelingAndWashingFuelType;
            case ResourceItemType.AdBlue: return Localizer.fuelingAndWashingAdBlue;
        }
        return "";
    }

    private get isInvoiceable(): boolean {
        if (ch.isNorway) {
            return false;
        }

        return this.fuelingAndWashingEnabled && this.canInvoiceFuelingAndWashing && this.isResourceInvoiceable;
    }

    private get isInvoiced(): boolean {
        if (ch.isNorway) {
            return this.reportItem.invoiced ?? false;
        }

        return this.reportItem.invoiced || this.invoicing;
    }

    private get invoicing(): boolean {
        if (this.reportItem.invoiced == null && this.reportItem.value > 0) {
            this.reportItem.invoiced = true
        }
        return this.reportItem.invoiced == true;
    }

    private get defaultTypeId(): string | null {
        return this.reportItem.valueTypeId;
    }

    protected isNextButtonDisabled(): boolean {
        return this.state.isLoading || !this.state.isValid;
    }

    public getWizardDescription(): string {
        return "";
    }

    public getReportItemValueOrDefault(): number {

        if (ch.isNorway && this.report.trackUnitOperatingHours && this.reportItem.resourceType === ResourceItemType.Operating) {
            return Number(this.report.trackUnitOperatingHours.toFixed(2));
        }

        return this.reportItem.value
            ? this.reportItem.value!
            : this.reportItem.default!;
    }

    public async imageUploaded(): Promise<void> {
        this.setState({
            isLoading: false,
        });
    }

    public async startImageUpload(image: FileModel): Promise<UploadFullImageResponse | null> {
        this.setState({
            isLoading: true,
        });

        return await FileService.uploadFullImageAsync(image);
    }

    public async nextAsync(): Promise<void> {
        await this._questionInputRef.current!.validateAsync();

        if (QuestionResourceReportItem.isValid(this.reportItem)) {
            await this.hideAlertAsync();
            await super.nextAsync();
            return;
        }

        if (this.reportItem.ok == null) {
            await this.alertErrorAsync(Localizer.questionPageAlertSelectPassOrFail, false, true);
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const valueTypes: BaseClassifier[] = await RentaToolsController.getValueTypes(this.reportItem.resourceType, this.reportItem.valueTypeIds);

        await this.initializeValueTypeIdAsync(valueTypes);

        const operatingHours: number | null = DeviceInfo.getTotalOperatingHours(RentaToolsController.device!);
        const maxOperatingHours: number | undefined = CalculateFutureOperatingHoursService.calculateMaxOperatingHoursFromLastService(operatingHours, RentaToolsController.device!);

        if (!this.reportItem.value) {
            if (this.reportItem.default) {
                this.reportItem.value = this.reportItem.default;
            } else {
                this.reportItem.value = this.reportItem.min ?? 0;
            }
        }

        RentaToolsController.saveContext();

        this.setState({valueTypes, calculatedMaxValue: maxOperatingHours ?? null});

        await this.validate();
    }

    private get canEditResource(): boolean {
        return this.canEdit && this.getReportItem().resourceType == ResourceItemType.Operating;
    }

    private get maxOperatingHours(): number | undefined {

        if (this.reportItem.resourceType != ResourceItemType.Operating) {
            return undefined;
        }

        const value: number | null = (!this.state.edit || (this.state.edit && this.isLatestReport))
            ? this.state.calculatedMaxValue
            : this.reportItem.max;

        return value ?? undefined;
    }

    private get isLatestReport(): boolean {
        return (this.report.operatingHours! >= DeviceInfo.getTotalOperatingHours(RentaToolsController.device!)!)
            && (this.report.startedAt.toDateString() == RentaToolsController.device!.lastOperatingHoursRecordTimestamp?.toDateString());
    }

    private get minOperatingHours(): number | undefined {

        if (this.reportItem.resourceType != ResourceItemType.Operating) {
            return undefined;
        }

        return this.reportItem.min ?? undefined;
    }

    private get trackUnitOperatingHoursText(): string {
        return `${Localizer.genericOperatingHoursFromTrackUnit}: ${this.report.trackUnitOperatingHours?.toFixed(1)}`;
    }

    private get displayTrackUnitHours(): boolean {
        return !ch.isNorway;
    }

    private renderData(): React.ReactNode {
        return (
            <React.Fragment>
                <div className={this.css(styles.resourceContainer)}>

                    {
                        (this.displayTrackUnitHours && this.resourceType == ResourceItemType.Operating && this.report.trackUnitOperatingHours) &&
                        (
                            <p className={styles.numberWidgetExtraText}>
                                {this.trackUnitOperatingHoursText}
                            </p>
                        )
                    }
                    <NumberWidget className={this.css(rentaToolsStyles.arsenalNumberWidget, styles.resourceInput, newStyles.numberWidget)}
                                  format={((this.reportItem.step != null) && (this.reportItem.step % 1 == 0)) ? "0" : "0.0"}
                                  step={this.reportItem.step || 0.0}
                                  readonly={this.preview && !this.state.edit}
                                  value={this.state.editedValue ?? this.getReportItemValueOrDefault()}
                                  onChange={async (sender, value) => this.state.edit ? await this.updateValueAsync(value) : await this.setValueAsync(value)}
                    />
                </div>

                {
                    (this.preview && this.canEditResource) && (
                        <ButtonContainer>
                            <ArsenalButton action fullWidth big
                                           id={"ri_edit_button"}
                                           className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                           type={!this.state.edit ? ButtonType.Orange : ButtonType.Light}
                                           label={!this.state.edit ? Localizer.genericEdit : Localizer.genericCancel}
                                           onClick={async  () => {await this.setState({edit: !this.state.edit, editedValue: null});}}
                            />
                            <ArsenalButton action fullWidth big
                                           id={"ri_edit_button"}
                                           className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                           disabled={!this.state.edit}
                                           type={ButtonType.Orange}
                                           label={Localizer.genericSave}
                                           onClick={async () => {await this.saveOperatingHours()}}
                            />
                        </ButtonContainer>
                    )
                }

                {
                    (this.fuelingAndWashingEnabled) && (
                        <>
                            {
                                (this.valueTypes.length > 0) &&
                                (
                                    <Dropdown required
                                              requiredType={DropdownRequiredType.Manual}
                                              id={`value_type`}
                                              className={this.css(rentaToolsStyles.arsenalDropdown, (this.desktop) && styles.desktopDropdownStyle)}
                                              disabled={this.preview}
                                              label={this.valueTypeLabel}
                                              items={this.valueTypes}
                                              selectedItem={this.defaultTypeId ?? undefined}
                                              onChange={async (sender, value) => this.setValueTypeAsync(value!)}
                                    />
                                )
                            }

                            {
                                (this.isInvoiceable) &&
                                (
                                    <div className={rentaToolsStyles.arsenalCheckboxes}>
                                        <Checkbox inline
                                                  readonly={this.preview || (this.reportItem.value == 0)}
                                                  label={this.invoiceFuelingAndWashingLabel}
                                                  inlineType={InlineType.Right}
                                                  value={this.isInvoiced}
                                                  onChange={async (sender, value) => this.setInvoicedAsync(value)}
                                        />
                                    </div>

                                )
                            }
                        </>
                    )
                }

            </React.Fragment>
        )
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <ArsenalQuestionInput ref={this._questionInputRef}
                                      model={this.reportItem}
                                      preview={this.preview}
                                      renderData={() => this.renderData()}
                                      convertImage={async (file) => await this.startImageUpload(file)}
                                      imageUploaded={async () => await this.imageUploaded()}
                                      onStateChange={() => this.validate()}
                />

            </React.Fragment>
        );
    }
}