import {FileModel, ILanguage, Utility} from "@renta-apps/athenaeum-toolkit";
import {ApplicationContext, ch, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "../providers/PageDefinitions";
import Context from "./Models/Context";
import UserContext from "../models/server/UserContext";
import Device from "./Models/Device";
import ReportDefinition from "./Models/ReportDefinition";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import Report from "./Models/Report";
import QuestionPicturesReportItem from "./Models/QuestionPicturesReportItem";
import GetLastReportsRequest from "../models/server/requests/GetLastReportsRequest";
import FindDeviceResponse from "../models/server/responses/FindDeviceResponse";
import AddCategoryMappingRequest from "../models/server/requests/AddCategoryMappingRequest";
import DeleteCategoryMappingRequest from "../models/server/requests/DeleteCategoryMappingRequest";
import DeviceInfo from "@/models/server/DeviceInfo";
import GetMaintenanceDevicesFiltersResponse from "../models/server/responses/GetMaintenanceDevicesFiltersResponse";
import ListUnInspectedDevicesRequest from "../models/server/requests/ListUnInspectedDevicesRequest";
import ServiceReportType from "@/pages/Models/ServiceReportType";
import GetServiceDevicesFiltersResponse from "@/models/server/responses/GetServiceDevicesFiltersResponse";
import ListUnServicedDevicesRequest from "@/models/server/requests/ListUnServicedDevicesRequest";
import EditServiceInvoiceDetailsRequest from "@/models/server/requests/EditServiceInvoiceDetailsRequest";
import {DeviceBanOfUse, DeviceCounterType, MaintenanceStatus, ResourceItemType, SaveServiceResponseType} from "@/models/Enums";
import GetFailedReportItemsRequest from "../models/server/requests/GetFailedReportItemsRequest";
import ReportItemInfo from "./Models/ReportItemInfo";
import RentaToolsConstants from "../helpers/RentaToolsConstants";
import DownloadReportPdfResponse from "../models/server/responses/DownloadReportPdfResponse";
import StartServiceResponse from "@/models/server/responses/StartServiceResponse";
import ServiceReport from "@/pages/Models/ServiceReport";
import SaveServiceRequest from "@/models/server/requests/SaveServiceRequest";
import SaveServiceResponse from "@/models/server/responses/SaveServiceResponse";
import ServiceAction from "@/pages/Models/ServiceAction";
import BaseReportDefinition from "@/pages/Models/BaseReportDefinition";
import GetServiceActionsResponse from "@/models/server/responses/GetServiceActionsResponse";
import GetServiceActionsRequest from "@/models/server/requests/GetServiceActionsRequest";
import GetInspectionPassThroughChartRequest from "@/models/server/requests/GetInspectionPassThroughChartRequest";
import InspectionPassThroughChart from "@/models/server/InspectionPassThroughChart";
import DevicesInMaintenanceChart from "@/models/server/DevicesInMaintenanceChart";
import ListServiceReportsRequest from "@/models/server/requests/ListServiceReportsRequest";
import DeviceFault from "@/pages/Models/DeviceFault";
import DeviceCounter from "@/pages/Models/DeviceCounter";
import GetMappingsResponse from "@/models/server/responses/GetMappingsResponse";
import GetLastReportsResponse from "@/models/server/responses/GetLastReportsResponse";
import GetServiceReportsResponse from "@/models/server/responses/GetServiceReportsResponse";
import StartServiceRequest from "@/models/server/requests/StartServiceRequest";
import SkipServiceRequest from "@/models/server/requests/SkipServiceRequest";
import SkipServiceResponse from "@/models/server/responses/SkipServiceResponse";
import User from "@/models/server/User";
import FuelType from "@/models/server/FuelType";
import ServiceExpense from "@/models/server/ServiceExpense";
import WashingType from "@/models/server/WashingType";
import AdBlue from "@/models/server/AdBlue";
import Depo from "@/models/server/Depo";
import BaseClassifier from "@/models/server/BaseClassifier";
import {IFooterLink} from "@renta-apps/athenaeum-react-components";
import ListAnnualInspectionDevicesRequest from "@/models/server/requests/ListAnnualInspectionDevicesRequest";
import GetAnnualInspectionDeviceFiltersResponse from "@/models/server/responses/GetAnnualInspectionDeviceFiltersResponse";
import DeviceContract from "@/models/server/DeviceContract";
import AddAnnualInspectionTypeMappingRequest from "@/models/server/requests/AddAnnualInspectionTypeMappingRequest";
import GetAnnualInspectionTypeMappingsResponse from "@/models/server/responses/GetAnnualInspectionTypeMappingsResponse";
import DeleteAnnualInspectionTypeMappingRequest from "@/models/server/requests/DeleteAnnualInspectionTypeMappingRequest";
import GetAnnualInspectionTypeItemsResponse from "@/models/server/responses/GetAnnualInspectionTypeItemsResponse";
import PhaseResponse from "@/models/server/responses/PhaseResponse";
import DeviceLocationResponse from "@/models/server/responses/DeviceLocationResponse";
import ToolsUtility from "@/helpers/ToolsUtility";
import {GetDeviceCodeFaultsRequest} from "@/models/server/requests/GetDeviceCodeFaultsRequest";
import {GetDeviceCodeFaultsResponse} from "@/models/server/responses/GetDeviceCodeFaultsResponse";
import GetOngoingReportResponse from "@/models/server/responses/GetOngoingReportResponse";
import OperatingHoursLimitRequest from "@/models/server/requests/OperatingHoursLimitRequest";
import OperatingHoursRecordDto from "@/models/server/OperatingHoursRecordDto";
import AdditionalExpense from "@/models/server/AdditionalExpense";
import {GetAdditionalExpensesByIdRequest} from "@/models/server/requests/GetAdditionalExpensesByIdRequest";
import GetContractsRequest from "@/models/server/requests/GetContractsRequest";
import DeviceFailedItemViewModel from "@/models/server/DeviceFailedItemViewModel";
import EndpointPaths from "@/common/EndpointPaths";
import RentaToolsFileStorage from "./RentaToolsFileStorage";
import RentaToolsStorage, {RentaToolsStorageTable} from "./RentaToolsStorage";
import TransformProvider from "../providers/TransformProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "../localization/Localizer";
import HttpClient from "@/common/HttpClient";
import DeviceObservation from "@/models/server/DeviceObservation";

class RentaToolsController {
    private _key: string | null = null;
    private _context: Context | null = null;
    private _initialized: boolean = false;
    private _initializing: boolean = false;

    private readonly MaxFailedReportsCount: number = 100;

    private get key(): string {
        if (this._key == null) {
            this._key = `${RentaToolsConstants.applicationName}.${ch.getSessionId()}.${RentaToolsController.name}`;
        }
        return this._key!;
    }

    public getDeviceCounter(device: Device, type: DeviceCounterType): number | null {
        const counter: DeviceCounter | null = (device.counters)
            ? device.counters.find(item => item.type == type) || null
            : null;
        return (counter) ? counter.value : null;
    }

    public findActiveFaults(device: Device, definitionItemId: string): DeviceFault[] {
        return (device.activeFaults)
            ? device.activeFaults.filter(fault => (fault.step != null) && (fault.step.id == definitionItemId))
            : [];
    }

    private createService(serviceDefinition: ServiceReportDefinition, device: Device, serviceType: ServiceReportType | null, previousOperatingHours: number | null): ServiceReport {
        const report = new ServiceReport();
        report.reportDefinition = serviceDefinition;
        report.reportDefinitionId = serviceDefinition.id;
        report.deviceId = device.id;
        report.deviceExternalId = device.externalId;
        report.user = this.userContext.user;
        report.date = Utility.now();
        report.startedAt = Utility.now();
        report.completedAt = Utility.now();
        report.faults = device.activeFaults || [];
        report.maintenance = device.maintenance;

        // Default services without a selection
        report.serviceType = null;
        report.serviceTypeId = null;
        report.actions = [];

        report.previousOperatingHours = previousOperatingHours;
        return report;
    }

    public saveContext(): void {
        const json: string = RentaToolsFileStorage.serialize(this.context);
        //do not wait, can be executed on background
        RentaToolsStorage.setAsync(this.key, json);
    }

    public async authorizeAsync(): Promise<void> {
        if (!this._initializing) {
            this._key = null;
            this._initialized = false;
            await this.initializeAsync();
        }
    }

    public async onLogoClickAsync(): Promise<void> {
        let route: PageRoute = (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagHomePageEnabled))
            ? PageDefinitions.homeRoute
            : PageDefinitions.loginRoute;

        if (ch.isAuthenticated) {
            route = PageDefinitions.dashboardRoute;
        }

        await PageRouteProvider.redirectAsync(route, false, false);
    }

    public async initializeAsync(): Promise<void> {
        if ((!this._initialized) && (!this._initializing)) {
            this._initializing = true;
            try {
                const json: string | null = await RentaToolsStorage.getAsync(this.key);
                this._context = (json) ? Context.parse(json!) : new Context();
                await RentaToolsFileStorage.processModelAsync(this._context, false);
                this._initialized = true;
            } finally {
                this._initializing = false;
            }
        }
    }

    public get context(): Context {
        if ((!this._initialized) || (this._context == null))
            throw Error(Localizer.get(Localizer.rentaToolsControllerErrorContextNotInitialized));

        return this._context!;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get footerLinks(): IFooterLink[] {
        const context: ApplicationContext | null = ch.findContext();
        switch (context?.country) {
            case "se":
            case "sv":
                return [
                    {
                        href: "https://www.renta.se/",
                        label: Localizer.footerFrontpage
                    },
                    {
                        href: "https://www.renta.se/kontaktuppgifter/",
                        label: Localizer.footerContact
                    }
                ];
            case "fi":
                return [
                    {
                        href: "https://renta.fi/",
                        label: Localizer.footerFrontpage
                    },
                    {
                        href: "https://www.renta.fi/fi/yhteystiedot/",
                        label: Localizer.footerContact
                    }
                ];
            default:
                return [];
        }
    }

    public get report(): Report | null {
        return this.context.report;
    }

    public get service(): ServiceReport | null {
        return this.context.service;
    }

    public get ongoingService(): ServiceReport | null {
        const service: ServiceReport | null = this.context.ongoingService;
        const expired: boolean = (
            (service != null) &&
            ((Utility.diff(Utility.now(), service.startedAt).totalMinutes > RentaToolsConstants.lockServiceTimeoutInMinutes))
        );

        if (expired) {
            this.context.ongoingService = null;
            this.saveContext();
        }

        return this.context.ongoingService;
    }

    public get device(): Device | null {
        return this.context.device;
    }
    public set device(value: Device | null) {
        this.context.device = value;
        this.saveContext();
    }

    public set failedReportItems(value: ReportItemInfo[] | null) {
        this.context.failedReportItems = value;
        this.saveContext();
    }

    public set reportDefinition(value: BaseReportDefinition | null) {
        this.context.reportDefinition = value;
        this.saveContext();
    }

    public get reportDefinition(): BaseReportDefinition | null {
        return this.context.reportDefinition;
    }

    public set reportPreview(value: Report | null) {
        this.context.report = value;
        this.context.preview = true;
        this.saveContext();
    }

    public get reportPreview(): Report | null {
        return this.context.report;
    }

    public set servicePreview(value: ServiceReport | null) {
        this.context.ongoingServicePreview = value;
        this.context.preview = true;
        this.saveContext();
    }


    public get servicePreview(): ServiceReport | null {
        return this.context.ongoingServicePreview;
    }

    public get preview(): boolean {
        return this.context.preview;
    }

    public get instructionBackRoute(): PageRoute | null {
        return this.context.instructionBackRoute;
    }

    public get languages(): ILanguage[] {
        const context: ApplicationContext | null = ch.findContext();

        if (context == null) {
            return [];
        }

        const supportedLanguages: string[] = context.settings.supportedLanguages;
        return Localizer.supportedLanguages.filter(language => supportedLanguages.some(s =>
            language.code === s));
    }

    public get supportUnServicedDevicesRequest(): boolean {
        return (ch.country == "se");
    }

    public findDeviceAsync(deviceExternalId: string): Promise<FindDeviceResponse> {
        return HttpClient.postAsync(EndpointPaths.DevicePaths.FindDevice, deviceExternalId);
    }

    public async refreshDeviceAsync(deviceExternalId: string): Promise<FindDeviceResponse> {
        const response: FindDeviceResponse = await HttpClient.postAsync(EndpointPaths.DevicePaths.RefreshDevice, deviceExternalId);
        await this.processSearchDeviceResponseAsync(response);

        return response;
    }

    public async searchDeviceByImageAsync(file: FileModel): Promise<void> {
        const response: FindDeviceResponse = await HttpClient.postAsync(EndpointPaths.DevicePaths.FindDeviceByImage, file);
        await this.processSearchDeviceResponseAsync(response);
    }

    public async findDeviceByIdAsync(deviceId: string): Promise<FindDeviceResponse> {
        return await HttpClient.postAsync(EndpointPaths.DevicePaths.FindDeviceById, deviceId);
    }

    public async getDeviceContractsAsync(deviceId: string, includeAll: boolean): Promise<DeviceContract[]> {
        const request = new GetContractsRequest();
        request.deviceId = deviceId;
        request.includeAll = includeAll;

        return await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.GetDeviceContracts, request);
    }

    public getMaintenanceDevicesFiltersAsync(): Promise<GetMaintenanceDevicesFiltersResponse> {
        return HttpClient.postAsync(EndpointPaths.DevicePaths.GetMaintenanceDevicesFilters);
    }

    public getUnInspectedDeviceCount(request: ListUnInspectedDevicesRequest): Promise<number> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.GetUnInspectedDeviceCount, request);
    }

    public getServiceDevicesFiltersAsync(): Promise<GetServiceDevicesFiltersResponse> {
        return HttpClient.postAsync(EndpointPaths.DevicePaths.GetServiceDevicesFilters);
    }

    public getAnnualInspectionDeviceFiltersAsync(): Promise<GetAnnualInspectionDeviceFiltersResponse> {
        return HttpClient.postAsync(EndpointPaths.DevicePaths.GetAnnualInspectionDeviceFilters);
    }

    public listUnServicedDevicesAsync(request: ListUnServicedDevicesRequest): Promise<DeviceInfo[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.ListUnServicedDevices, request);
    }

    public listUnServicedDevicesCountAsync(request: ListUnServicedDevicesRequest): Promise<number> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.GetUnServicedDeviceCount, request);
    }

    public listAnnualInspectionDevicesAsync(request: ListAnnualInspectionDevicesRequest): Promise<DeviceInfo[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.ListAnnualInspectionDevices, request);
    }

    public findServiceReportDefinitionAsync(reportDefinitionId: string): Promise<ServiceReportDefinition | null> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportDefinition.FindServiceReportDefinition, reportDefinitionId);
    }

    public getFailedReportItemsAsync(deviceExternalId: string): Promise<DeviceFailedItemViewModel[]> {
        const request = new GetFailedReportItemsRequest();
        request.deviceExternalId = deviceExternalId;
        request.count = this.MaxFailedReportsCount;
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportPaths.GetFailedReportItems, request);
    }

    public async getServiceExpensesAsync(includeDeleted: boolean = false) {
        let serviceExpenses: ServiceExpense[] = await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ClassifierPaths.GetServiceExpenses);
        if (!includeDeleted) {
            serviceExpenses = serviceExpenses.filter(item => !item.isDeleted)
        }
        return serviceExpenses;
    }

    public getUserDeposAsync(): Promise<Depo[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DepoPaths.GetUserDepos);
    }

    public getDeviceTypesAsync(productGroupId: string | null = null): Promise<string[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.GetDeviceTypes, productGroupId);
    }

    public deleteDatasetsAsync(datasetsNames: string): Promise<void> {
         return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.AdminConsolePaths.DeleteDatasets, datasetsNames);
    }

    public deleteImportJobsAsync(importsJobsNames: string): Promise<void> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.AdminConsolePaths.DeleteImportJobs, importsJobsNames);
    }

    public async searchDeviceAsync(deviceExternalId: string): Promise<void> {
        const response: FindDeviceResponse = await this.findDeviceAsync(deviceExternalId);
        await this.processSearchDeviceResponseAsync(response);
    }

    public async navigateToDevicePageAsync(deviceExternalId: string) {
        const response: FindDeviceResponse = await this.findDeviceAsync(deviceExternalId);

        if (!response || !response.device) {
            this.context.device = null;
            this.saveContext();

            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
            return;
        }

        this.context.device = response.device;
        this.saveContext();

        await PageRouteProvider.redirectAsync(PageDefinitions.deviceRoute);
    }

    public async processSearchDeviceResponseAsync(response: FindDeviceResponse): Promise<void> {
        const device: Device | null = response.device;

        if (device) {

            this.context.device = device;

            this.saveContext();

            await PageRouteProvider.redirectAsync(PageDefinitions.deviceRoute);

        } else {

            this.context.device = null;

            this.context.failedReportItems = null;

            this.saveContext();

            await ch.alertErrorAsync(Localizer.dashboardPageNotFound, true);
        }
    }

    public getServiceReportsAsync(deviceExternalId: string, startDate: Date | null, pageNumber: number, pageSize: number): Promise<GetServiceReportsResponse> {
        const request = new ListServiceReportsRequest();
        request.deviceExternalId = deviceExternalId;
        request.startDate = startDate;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        return HttpClient.postAsync(EndpointPaths.ReportPaths.ListServiceReports, request);
    }

    public getLastReportIdAsync(deviceId: string): Promise<string | null> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportPaths.GetLastReportId, deviceId);
    }

    public getLastReportsAsync(deviceExternalId: string, latestOnly: boolean = false): Promise<GetLastReportsResponse> {
        const request = new GetLastReportsRequest();
        request.deviceExternalId = deviceExternalId;
        request.latestOnly = latestOnly;

        return HttpClient.postAsync(EndpointPaths.ReportPaths.GetLastReports, request);
    }

    public getReportAsync(reportId: string): Promise<Report> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportPaths.GetReport, reportId);
    }

    public getServiceReportAsync(reportId: string): Promise<ServiceReport> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportPaths.GetServiceReport, reportId);
    }

    public getReportDefinitionsAsync(): Promise<ReportDefinition[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportDefinition.GetReportDefinitions);
    }

    public getServiceReportDefinitionsAsync(): Promise<ServiceReportDefinition[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportDefinition.GetReportDefinitions);
    }

    public getMappingsAsync(): Promise<GetMappingsResponse> {
        return HttpClient.postAsync(EndpointPaths.MappingsPaths.GetMappings);
    }

    public addCategoryMapping(request: AddCategoryMappingRequest): Promise<void> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.MappingsPaths.AddCategoryMapping, request);
    }

    public deleteCategoryMapping(request: DeleteCategoryMappingRequest): Promise<void> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.MappingsPaths.DeleteCategoryMapping, request);
    }

    public getAnnualInspectionTypeMappingsAsync(): Promise<GetAnnualInspectionTypeMappingsResponse> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.MappingsPaths.GetAnnualInspectionTypeMappings);
    }

    public addAnnualInspectionTypeMapping(request: AddAnnualInspectionTypeMappingRequest): Promise<void> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.MappingsPaths.AddAnnualInspectionTypeMapping, request);
    }

    public deleteAnnualInspectionTypeMapping(request: DeleteAnnualInspectionTypeMappingRequest): Promise<void> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.MappingsPaths.DeleteAnnualInspectionTypeMapping, request);
    }

    public getAnnualInspectionTypeItemsByProductGroup(productGroupId: string): Promise<GetAnnualInspectionTypeItemsResponse> {
        return HttpClient.postAsync(EndpointPaths.MappingsPaths.GetAnnualInspectionTypeItemsByProductGroup, productGroupId);
    }

    public async resetStatusAsync(deviceExternalId: string): Promise<void> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.ResetDeviceStatus, deviceExternalId);
    }

    public async getServiceActionsAsync(request: GetServiceActionsRequest | null = null): Promise<ServiceAction[]> {
        request = request || new GetServiceActionsRequest();

        const response: GetServiceActionsResponse = await HttpClient.postAsync(EndpointPaths.ActionPaths.GetServiceActions, request);

        return response.serviceActions;
    }

    public getWashingTypesAsync(): Promise<WashingType[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ClassifierPaths.GetWashingTypes);
    }

    public getAdBluesAsync(): Promise<AdBlue[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ClassifierPaths.GetAdBlues);
    }

    public getAdditionalExpensesAsync(): Promise<AdditionalExpense[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ClassifierPaths.GetAdditionalExpenses);
    }

    public getAdditionalExpensesByIdAsync(additionalExpensesIds: string[], includeDeleted: boolean = false): Promise<AdditionalExpense[]> {
        const request = new GetAdditionalExpensesByIdRequest();
        request.additionalExpenseIds = additionalExpensesIds;
        request.includeDeleted = includeDeleted;

        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReturnInspectionPaths.GetAdditionalExpensesById, request);
    }

    public getFuelTypesAsync(): Promise<FuelType[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ClassifierPaths.GetFuelTypes);
    }

    public async getInvoiceableWashingTypesAsync(): Promise<WashingType[]> {
        let washingTypes: WashingType[] = await this.getWashingTypesAsync();
        return washingTypes.filter(item => item.isInvoiceable)
    }

    public async getInvoiceableAdBluesAsync(): Promise<AdBlue[]> {
        let adBlues: AdBlue[] =  await this.getAdBluesAsync();
        return adBlues.filter(item => item.isInvoiceable);
    }

    public async getInvoiceableFuelTypesAsync(): Promise<FuelType[]> {
        let fuelTypes: FuelType[] =  await this.getFuelTypesAsync();
        return fuelTypes.filter(item => item.isInvoiceable);
    }

    public getManualFuelStep(): Promise<number> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ClassifierPaths.GetManualFuelStep);
    }

    public async getValueTypes(resourceType: ResourceItemType | null, ids: string[] | null): Promise<BaseClassifier[]> {
        let items: BaseClassifier[] = [];
        switch (resourceType) {
            case ResourceItemType.Fueling:
                items = await this.getFuelTypesAsync();
                break;
            case ResourceItemType.AdBlue:
                items = await this.getAdBluesAsync();
                break;
            case ResourceItemType.Washing:
                items = await this.getWashingTypesAsync();
                break;
        }
        items = items.filter(item => !item.isDeleted);
        if ((ids != null) && (ids.length > 0)) {
            items = items.filter(item => ids.includes(item.id));
        }
        return items;
    }

    public getReturnInspectionsChartAsync(request: GetInspectionPassThroughChartRequest | null = null): Promise<InspectionPassThroughChart> {
        request = request || new GetInspectionPassThroughChartRequest();
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ChartsPaths.GetInspectionPassThroughChart, request);
    }

    public getDevicesInMaintenanceChartAsync(): Promise<DevicesInMaintenanceChart> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ChartsPaths.GetDevicesInMaintenanceChart);
    }

    public async startServiceAsync(device: Device, reportDefinition: ServiceReportDefinition): Promise<void> {
        const request = new StartServiceRequest();
        request.deviceId = device.id;

        const response: StartServiceResponse = await HttpClient.postAsync(EndpointPaths.ReportPaths.StartService, request);

        if (response.success) {
            const service: ServiceReport = this.createService(reportDefinition, device, response.serviceType, response.previousOperatingHours);

            this.context.device = device;
            this.context.service = service;
            this.context.ongoingService = service;
            this.context.preview = false;

            device.underService = true;
            device.serviceStartedById = ch.getUserId();
            device.serviceStartedBy = ch.getUser<User>();
            device.serviceStartedAt = Utility.now();

            this.saveContext();

            await PageRouteProvider.redirectAsync(PageDefinitions.deviceServiceRoute);

            return;
        }

        const message: string = (response.inspector != null)
            ? Utility.format(Localizer.get(Localizer.rentaToolsControllerMessageServiceCannotBeStartedAlreadyUnderService, device.externalId, TransformProvider.toString(response.inspector)))
            : Localizer.get(Localizer.rentaToolsControllerMessageServiceCannotBeStartedServiceNotNeeded, device.externalId);

        await ch.alertErrorAsync(message, true);

        await this.searchDeviceAsync(device.externalId);
    }

    public async continueServiceAsync(): Promise<void> {

        if (this.context.ongoingService) {

            const deviceId: string = this.context.ongoingService.deviceId;

            const response: FindDeviceResponse = await this.findDeviceByIdAsync(deviceId);

            const device: Device | null = response.device;
            const deviceExternalId: string = (device) ? device.externalId : "";

            const supportService: boolean = (device != null && device.serviceReportDefinitionId != null);
            const underService: boolean = (device != null && device.underService);
            const underMyService: boolean = (device != null && device.serviceStartedById == ch.getUserId());
            const canContinue = (supportService && underMyService);

            if (canContinue) {

                this.context.device = device;
                this.context.service = this.context.ongoingService;
                this.context.preview = false;

                this.saveContext();

                await PageRouteProvider.redirectAsync(PageDefinitions.deviceServiceRoute);

            } else {

                this.context.service = null;
                this.context.ongoingService = null;
                this.context.device = null;

                this.saveContext();

                let message: string = Localizer.errorPageTitle;

                if (!supportService) {
                    message = Utility.format(Localizer.get(Localizer.rentaToolsControllerMessageServiceCannotBeContinuedNotSupportServices, deviceExternalId));
                } else if (underService && !underMyService) {
                    message = Utility.format(Localizer.get(Localizer.rentaToolsControllerMessageServiceCannotBeContinuedAlreadyUnderService, deviceExternalId, TransformProvider.toString(device!.inspectionStartedBy)));
                }

                await ch.alertErrorAsync(message, true);

                await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
            }
        }
    }

    public async stopServiceAsync(deviceId: string | null = null): Promise<void> {

        if ((!deviceId) && (this.context.ongoingService)) {
            deviceId = this.context.ongoingService.deviceId;
        }

        if (deviceId) {

            const device: Device = await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportPaths.StopService, deviceId);

            if ((this.device) && (this.device.id == deviceId)) {
                this.context.device = device;
            }

            this.context.service = null;
            this.context.ongoingService = null;
            this.context.preview = false;

            this.saveContext();
        }
    }

    public getMessage(maintenanceReasons: string, hasFaults: boolean, device: Device | null): string {
        let message = Localizer.summaryPageReturnInspectionSaved;

        if (hasFaults) {
            message += `\n${Localizer.summaryPageReportSavedFaultsWereFound}`;
        }

        const serviceAndRepairEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
        if (device && serviceAndRepairEnabled && (ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresService) || ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresRepair))) {
            message += `\n${Localizer.summaryPageReportSavedServiceTriggers.format(maintenanceReasons)}`;
        }

        return message;
    }

    public async saveServiceAsync(operatingHours: number | null = null): Promise<void> {
        const service: ServiceReport | null = this.service;
        const device: Device | null = this.device;

        if ((service) && (device)) {
            const request = new SaveServiceRequest();
            request.deviceId = device.id;
            request.userId = service.user!.id;
            request.reportDefinitionId = service.reportDefinitionId;
            request.date = service.date;
            request.startedAt = service.startedAt;
            request.depoCostPool = device.depoCostPool;
            request.serviceTypeId = service.serviceTypeId;
            request.serviceTypeName = service.serviceType?.name ?? null;
            request.dontSendServiceDate = service.serviceType?.dontSendServiceDate ?? false;
            request.actionIds = service.actions!.map(action => action.id);
            request.faults = (service.faults)
                ? service.faults.filter(item => item.fixed)
                : [];
            request.comment = service.comment;
            request.servicedByRenta = service.servicedByRenta;
            request.invoiceNumber = service.invoiceNumber;
            request.cost = service.cost;
            request.operatingHours = operatingHours;
            request.serviceExpenseId = (service.serviceExpense) ? service.serviceExpense.id : null;
            request.depoId = (service.depo) ? service.depo.id : null;
            request.locationType = service.locationType;
            request.isRepairWithNoFaults = service.isRepairWithNoFaults;
            request.serviceDuration = service.serviceDuration;
            request.repairDuration = service.repairDuration;

            const response: SaveServiceResponse = await HttpClient.postAsync(EndpointPaths.ReportPaths.SaveService, request);

            if (response.success) {
                this.context.ongoingService = null;
                this.context.preview = true;

                service.id = response.serviceId;
                service.completedAt = Utility.now();

                this.context.device = response.device;

                this.saveContext();

                // noinspection ES6MissingAwait
                RentaToolsStorage.clearDataAsync(RentaToolsStorageTable.Files);

                await PageRouteProvider.redirectAsync(PageDefinitions.deviceRoute);
                await ch.alertMessageAsync(Localizer.deviceServicePageAlertServiceReportSaved);
            }else if(!response.success){
                const alertText = this.getSaveServiceAlert(response);
                if(alertText) {
                    await ch.alertErrorAsync(alertText, false, true);
                }
            }
            else {
                await this.searchDeviceAsync(device.externalId);
                await ch.alertErrorAsync(Localizer.deviceServicePageAlertServiceCannotBeSaved, true);
            }
        }
    }

    public getSaveServiceAlert(response: SaveServiceResponse): string | null {

        if (response.operatingHoursBefore && response.operatingHoursAfter) {
            return Localizer.servicePageOperatingHoursRangeResponse.format(response.operatingHoursBefore, ToolsUtility.toDateString(response.before), response.operatingHoursAfter,ToolsUtility.toDateString(response.after) )
        }

        switch (response.type) {
            case SaveServiceResponseType.InvalidOperatingHours:
                return Localizer.operatingHoursCannotBeLesserThan.format(response.operatingHoursAfter, ToolsUtility.toDateString(response.after));

            case SaveServiceResponseType.InvalidSameDayOperatingHours:
                return Localizer.operatingHoursCannotBeDifferentInTheSameDay.format(response.operatingHoursBefore, ToolsUtility.toDateString(response.before));
        }

        return null;
    }

    public getSkipServiceAlert(response: SkipServiceResponse): string {
        return Localizer.servicePageOperatingHoursRangeResponse.format(response.operatingHoursBefore, ToolsUtility.toDateString(response.before), response.operatingHoursAfter,ToolsUtility.toDateString(response.after) )
    }

    public async editServiceAsync(request: EditServiceInvoiceDetailsRequest): Promise<SaveServiceResponse> {
        const response: SaveServiceResponse = await HttpClient.postAsync(EndpointPaths.ReportPaths.EditServiceInvoiceDetails, request);

        if(!response.success){
            const alertText = this.getSaveServiceAlert(response);
            if(alertText) {
                await ch.alertErrorAsync(alertText, true);
            }
        }

        if (response.success) {
            this.context.ongoingServicePreview = response.report;
            this.saveContext();
        }

        return response;
    }

    public async skipServiceAsync(serviceDefinition: ServiceReportDefinition, comment: string, lastServiceOperatingHours: number | null = null, lastServiceDate: Date | null = null): Promise<void> {

        if (this.device) {
            const request = new SkipServiceRequest();
            request.deviceId = this.device.id;
            request.userId = this.userContext.user!.id;
            request.reportDefinitionId = serviceDefinition.id;
            request.comment = comment;
            request.date = Utility.now();
            request.startedAt = Utility.now();
            request.lastServiceOperatingHours = lastServiceOperatingHours;
            request.lastServiceDate = lastServiceDate;

            const response: SkipServiceResponse = await HttpClient.postAsync(EndpointPaths.ReportPaths.SkipService, request);

            if (response.success) {
                this.device = response.device;
                this.saveContext();
            }else{
                const alertText = this.getSkipServiceAlert(response);
                if(alertText) {
                    await ch.alertErrorAsync(alertText, false, true);
                }
            }
        }
    }

    public async clearReportAsync(): Promise<void> {
        this.context.report = null;
        this.context.device = null;
        this.context.preview = false;

        this.saveContext();
    }

    public async previewPicturesAsync(report: Report | null, reportItem: QuestionPicturesReportItem): Promise<void> {

        this.context.picturePreviewReport = report;
        this.context.picturePreviewReportItem = reportItem;
        this.context.picturePreviewBackRoute = ch.getPage().route;

        this.saveContext();

        await PageRouteProvider.redirectAsync(PageDefinitions.picturesPreviewRoute);
    }

    public downloadReportPdfAsync(reportId: string): Promise<DownloadReportPdfResponse> {
        return HttpClient.postAsync(EndpointPaths.ReportPaths.DownloadReportPdf, reportId);
    }


    public async loadDeviceAsync(deviceId: string): Promise<void> {
        const findDeviceResponse = await this.findDeviceByIdAsync(deviceId);
        if (findDeviceResponse.device){
            this.device = findDeviceResponse.device;
        }
    }

    public getOperatingHoursLimitsAsync(request: OperatingHoursLimitRequest): Promise<OperatingHoursRecordDto[]> {
        return HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.ReportPaths.GetOperatingHoursLimits, request);
    }

    public getDeviceLocationAsync(deviceExternalId: string): Promise<DeviceLocationResponse> {
        return HttpClient.postAsync(EndpointPaths.DevicePaths.GetDeviceLocation, deviceExternalId);
    }

    public async loadOngoingReportDeviceExternalId(): Promise<string | null> {
        const response: GetOngoingReportResponse = await HttpClient.postAsync(EndpointPaths.ReturnInspectionPaths.GetOngoingReport);

        if (response && response.success) {
            return response.deviceExternalId;
        }

        return null;
    }

    public isDeviceInBanOfUse() {
        if (!this.device) {
            return false;
        }

        return (this.device.deviceBanOfUse == DeviceBanOfUse.Banned || this.device.deviceBanOfUse == DeviceBanOfUse.NeedBan);
    }

    public getPhasesAsync(): Promise<PhaseResponse> {
        return HttpClient.getAsync(EndpointPaths.PhasePaths.GetPhases);
    }

    public getDeviceFaultCode(request : GetDeviceCodeFaultsRequest) : Promise<GetDeviceCodeFaultsResponse>{
        return HttpClient.postAsync<GetDeviceCodeFaultsResponse>(EndpointPaths.DevicePaths.GetDeviceFaultsCode, request)
    }

    public isDevelopment(): boolean {
        return ch.getContext().isDevelopment;
    }

    public isStaging(): boolean {
        return ch.getContext().isStaging;
    }

    public isProduction(): boolean {
        return !(this.isDevelopment() || this.isStaging());
    }
}

//Singleton
export default new RentaToolsController();